import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export default function BlogPost({ data }) {
  // Fallback for missing data
  if (!data?.beehiivPost) {
    return (
      <Layout black={false}>
        <SEO title="Post Not Found" />
        <section className="py-15 py-xl-20 pb-xl-15">
          <div className="container mt-10">
            <h1>Post Not Found</h1>
            <p>Sorry, this post could not be loaded.</p>
          </div>
        </section>
      </Layout>
    );
  }

  const post = data.beehiivPost;

  // 1) Strip out Beehiiv's "Powered by beehiiv" footer div:
  const sanitizedHTML = (post.rss_content ?? "").replace(
    /<div class='beehiiv__footer'[^>]*>[\s\S]*?<\/div>/,
    ""
  );
  

  return (
    <Layout black={false}>
      <SEO title={post.title} />

      {/* 
        SECTION 1: Post Title & Date
        Replicates your .container / .row / .col styling from PrivacyPage 
      */}
      <section className="py-15 py-xl-20 pb-xl-15">
        <div className="container mt-10">
          <h1>{post.title}</h1>
          <p>{post.date}</p>
        </div>
      </section>

      {/* 
        SECTION 2: Main Post Content
        We place all Beehiiv content in a single .col-md-12 
      */}
      <section>
        <div className="container">
          <div className="row g-4 justify-content-between">
            <div className="col-md-12">
              {/* 
                Dangerously set the sanitized HTML (no Beehiiv footer)
              */}
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    beehiivPost(slug: { eq: $slug }) {
      title
      date
      rss_content
    }
  }
`;
